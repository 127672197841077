import { FeatureRoute } from "./models";

type AccountApiNames = "List" | "Search";
type AccountGroupApiNames =
  | "BulkCreate"
  | "Create"
  | "Delete"
  | "Id"
  | "List"
  | "Search"
  | "Update"
  | "Accounts"; // Should we allow for some level of nesting here if we wish to move to something more restful?
type AdminApiNames = "AccountGroupSearch" | "EmployeeList";
type ClientApiNames = "Delete" | "Id" | "List" | "Update";
type EmployeeApiNames = "Id" | "List";
type NoteApiNames = "Create" | "List" | "Update";
type OnboardingApiNames = "AddEnvelope" | "Get" | "List" | "UpdateEnvelope";
type PlanApiNames = "List";
type ProspectApiNames = "List" | "Update";
type TeamApiNames =
  | "Admin"
  | "Create"
  | "List"
  | "Get"
  | "Update"
  | "AddMember"
  | "GetMember"
  | "RemoveMember"
  | "Migrate"
  | "Roles";
type UserApiNames = "Teams";

export interface ApiRoutes {
  readonly Account: FeatureRoute<AccountApiNames>;
  readonly AccountGroup: FeatureRoute<AccountGroupApiNames>;
  readonly Admin: FeatureRoute<AdminApiNames>;
  readonly Client: FeatureRoute<ClientApiNames>;
  readonly Employee: FeatureRoute<EmployeeApiNames>;
  readonly Notes: FeatureRoute<NoteApiNames>;
  readonly Onboarding: FeatureRoute<OnboardingApiNames>;
  readonly Plan: FeatureRoute<PlanApiNames>;
  readonly Prospect: FeatureRoute<ProspectApiNames>;
  readonly Team: FeatureRoute<TeamApiNames>;
  readonly User: FeatureRoute<UserApiNames>;
}

export const API: ApiRoutes = {
  Account: {
    List: {
      getUrl: () => "account",
      method: "GET",
      scope: "team",
    },
    Search: {
      getUrl: () => "account/search",
      method: "GET",
      scope: "team",
    },
  },
  Admin: {
    AccountGroupSearch: {
      getUrl: (employeeId: string) => `admin/account-groups?employeeId=${employeeId}`,
      method: "GET",
      scope: "global",
    },
    EmployeeList: {
      getUrl: () => `admin/employee`,
      method: "GET",
      scope: "global",
    },
  },
  AccountGroup: {
    BulkCreate: {
      getUrl: () => "account-group/bulk",
      method: "POST",
      scope: "team",
    },
    Create: {
      getUrl: () => "account-group",
      method: "POST",
      scope: "team",
    },
    Delete: {
      getUrl: (accountGroupId) => `account-group/${accountGroupId}`,
      method: "DELETE",
      scope: "team",
    },
    Id: {
      getUrl: (accountGroupId) => `account-group/${accountGroupId}`,
      method: "GET",
      scope: "team",
    },
    List: {
      getUrl: () => "account-group",
      method: "GET",
      scope: "team",
    },
    Search: {
      getUrl: () => "account-group/search",
      method: "GET",
      scope: "team",
    },
    Update: {
      getUrl: (accountGroupId) => `account-group/${accountGroupId}`,
      method: "PATCH",
      scope: "team",
    },
    Accounts: {
      getUrl: (accountGroupId) => `account-group/${accountGroupId}/accounts`,
      method: "GET",
      scope: "team",
    },
  },
  Client: {
    Delete: {
      getUrl: (householdId: string, clientId: string) =>
        `account-group/${householdId}/client/${clientId}`,
      method: "DELETE",
      scope: "team",
    },
    Id: {
      getUrl: (householdId: string, clientId: string) =>
        `account-group/${householdId}/client/${clientId}`,
      method: "GET",
      scope: "team",
    },
    List: {
      getUrl: () => "client",
      method: "GET",
      scope: "team",
    },
    Update: {
      getUrl: (householdId: string, clientId: string) =>
        `account-group/${householdId}/client/${clientId}`,
      method: "PATCH",
      scope: "team",
    },
  },
  Employee: {
    List: {
      getUrl: () => "admin/employee",
      method: "GET",
      scope: "global",
    },
    Id: {
      getUrl: (employeeId) => `employee/${employeeId}`,
      method: "GET",
      scope: "team",
    },
  },
  Notes: {
    Create: {
      getUrl: (householdId) => `account-group/${householdId}/call-notes`,
      method: "POST",
      scope: "team",
    },
    List: {
      getUrl: (householdId) => `account-group/${householdId}/call-notes`,
      method: "GET",
      scope: "team",
    },
    Update: {
      getUrl: (householdId, noteId) => `account-group/${householdId}/call-notes/${noteId}`,
      method: "PUT",
      scope: "team",
    },
  },
  Onboarding: {
    AddEnvelope: {
      getUrl: (householdId: string) => `account-group/${householdId}/envelope`,
      method: "POST",
      scope: "team",
    },
    Get: {
      getUrl: (householdId: string) => `account-group/${householdId}/onboard`,
      method: "GET",
      scope: "team",
    },
    List: {
      getUrl: () => "client/onboardings",
      method: "GET",
      scope: "team",
    },
    UpdateEnvelope: {
      getUrl: (householdId: string, envelopeId: string) =>
        `account-group/${householdId}/onboard/${envelopeId}`,
      method: "PUT",
      scope: "team",
    },
  },
  Plan: {
    List: {
      getUrl: () => "plan",
      method: "GET",
      scope: "global",
    },
  },
  Prospect: {
    List: {
      getUrl: () => "prospects",
      method: "GET",
      scope: "team",
    },
    Update: {
      getUrl: (householdId: string, prospectId: string) =>
        `account-group/${householdId}/prospects/${prospectId}`,
      method: "PUT",
      scope: "team",
    },
  },
  Team: {
    Admin: {
      getUrl: (id: string) => `teams/${id}/admin`,
      method: "GET",
      scope: "global",
    },
    Get: {
      getUrl: (id: string) => `teams/${id}`,
      method: "GET",
      scope: "team",
    },
    Create: {
      getUrl: () => "teams",
      method: "POST",
      scope: "global",
    },
    Update: {
      getUrl: (id: string) => `teams/${id}`,
      method: "PATCH",
      scope: "global",
    },
    List: {
      getUrl: () => "teams",
      method: "GET",
      scope: "team",
    },
    AddMember: {
      getUrl: (id: string) => `teams/${id}/member`,
      method: "POST",
      scope: "global",
    },
    GetMember: {
      getUrl: (id: string, employeeId: string) => `teams/${id}/member/${employeeId}`,
      method: "GET",
      scope: "global",
    },
    RemoveMember: {
      getUrl: (id: string, employeeId: string) => `teams/${id}/member/${employeeId}`,
      method: "DELETE",
      scope: "global",
    },
    Migrate: {
      getUrl: (id: string) => `teams/${id}/migrate-account-groups`,
      method: "POST",
      scope: "global",
    },
    Roles: {
      getUrl: () => "team-roles",
      method: "GET",
      scope: "global",
    },
  },
  User: {
    Teams: {
      getUrl: () => "user/teams",
      method: "GET",
      scope: "team",
    },
  },
} as const;
