import {
  InfiniteData,
  UseInfiniteQueryResult,
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { SortingState } from "@tanstack/react-table";
import axios, { AxiosResponse } from "axios";
import { ProspectsResponse } from "./models";
import { UpdateProspect } from "./validation";
import { DefaultAPIParams } from "feature/filtersV2/models";
import { Routes } from "feature/routes";
import { Client } from "feature/user/models";
import { userKeys } from "feature/user/queries";
import { PaginatedResponse } from "lib/api";
import { CompoundProspect } from "lib/services/compound/models";
import { useAppContext } from "providers/AppContextProvider";

const formatListKey = (fields: string[] = []) => {
  if (fields.length === 0) {
    return "-";
  }
  return fields.join("-");
};

export const prospectKeys = {
  all: ["prospects"] as const,
  filtered: (apiParams: DefaultAPIParams) => {
    const { filter = {}, ascFields, descFields, includeDescendantOrganizations = true } = apiParams;
    const ascFieldKey = formatListKey(ascFields);
    const descFieldKey = formatListKey(descFields);
    return [
      ...prospectKeys.all,
      {
        filter,
        ascFieldKey,
        descFieldKey,
        includeDescendantOrganizations,
      },
    ] as const;
  },
};

export interface Filters {
  readonly search?: string;
  readonly sorting: SortingState;
}

interface PageParam {
  readonly cursor?: string;
}

const getPagedCompoundProspects = async (
  filters: Filters,
  pageParam?: PageParam
): Promise<PaginatedResponse<CompoundProspect> & { fullSize: number | undefined }> => {
  const params = new URLSearchParams();
  if (pageParam?.cursor) {
    params.set("cursor", pageParam.cursor);
  }

  if (filters.search) {
    params.set("search", filters.search);
  }
  filters.sorting.forEach((field) => {
    if (field.desc) {
      params.append("descField", field.id);
    } else {
      params.append("ascField", field.id);
    }
  });

  const response: AxiosResponse<
    PaginatedResponse<CompoundProspect> & { fullSize: number | undefined }
  > = await axios.request({
    method: "GET",
    url: "/api/admin/compound-prospects",
    params,
  });
  return response.data;
};

export const useCompoundProspectsQuery = (
  filters: Filters
): UseInfiniteQueryResult<
  InfiniteData<PaginatedResponse<CompoundProspect> & { fullSize: number | undefined }>
> =>
  useInfiniteQuery({
    queryKey: [filters.search, filters.sorting, "compound prospects"],
    queryFn: ({ pageParam }) => getPagedCompoundProspects(filters, pageParam),
    getNextPageParam: (lastPage) => (lastPage.cursor ? { cursor: lastPage.cursor } : undefined),
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60 * 12, // 12 hours - just a long period of time (default 5min)
    retry: 1,
    initialPageParam: {},
  });

export const useProspectUpdateMutation = (householdId: string, prospectId: string) => {
  const queryClient = useQueryClient();
  const { featureFlags, selectedTeams } = useAppContext();
  const routeConfig = Routes.API.Prospect.Update;

  const url = Routes.Utils.apiRouteWithTeam({
    url: routeConfig.getUrl(householdId, prospectId),
    scope: routeConfig.scope,
    selectedTeams,
    featureFlagEnabled: featureFlags["teams-access-control"] === true,
  });

  return useMutation({
    mutationFn: (data: UpdateProspect) => {
      const response = axios.request<UpdateProspect, AxiosResponse<Client>>({
        method: routeConfig.method,
        url,
        data,
      });
      return response;
    },

    onSuccess: async () =>
      queryClient.invalidateQueries({
        queryKey: userKeys.client(prospectId),
      }),
  });
};

const getPagedProspects = async (
  url: string,
  filters: DefaultAPIParams,
  pageParam?: PageParam
): Promise<PaginatedResponse<Client> & { fullSize: number | undefined }> => {
  const response: AxiosResponse<PaginatedResponse<Client> & { fullSize: number | undefined }> =
    await axios.request({
      method: Routes.API.Prospect.List.method,
      url,
      params: {
        cursor: pageParam?.cursor,
        ...filters,
      },
      paramsSerializer: {
        indexes: null,
      },
    });
  return response.data;
};

export const useProspectsQuery = (
  filters: DefaultAPIParams
): UseInfiniteQueryResult<InfiniteData<ProspectsResponse>> => {
  const { featureFlags, selectedTeams } = useAppContext();
  const routeConfig = Routes.API.Prospect.List;

  const url = Routes.Utils.apiRouteWithTeam({
    url: routeConfig.getUrl(),
    scope: routeConfig.scope,
    selectedTeams,
    featureFlagEnabled: featureFlags["teams-access-control"] === true,
  });

  return useInfiniteQuery({
    queryKey: prospectKeys.filtered(filters),
    queryFn: async ({ pageParam }) => getPagedProspects(url, filters, pageParam),
    getNextPageParam: (lastPage) => (lastPage.cursor ? { cursor: lastPage.cursor } : undefined),
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60 * 12, // 12 hours - just a long period of time (default 5min)
    retry: 1,
    initialPageParam: {},
  });
};
